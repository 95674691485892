:root {
    --sidebar-factor: 0.8rem;
    --sidebar-width: calc(15 * var(--sidebar-factor));
    --sidebar-background: #fff;
    --sidebar-text-color: #0f141e;
    --sidebar-label-color: #929496;
    --sidebar-icon-color: #727377;
    --sidebar-hover-background: #e7f6fa;
    --sidebar-hover-text-color: #3296bb;
    --sidebar-scrollbar-color: #c1dfeb;
    --sidebar-border: 1px solid #eaeaea;
    --sidebar-menu-item-count: 0;
    --sidebar-tooltip-background: #0f141e;
    --sidebar-tooltip-text-color: #fff;
    --sidebar-shadow: 2px 0px 10px 0px rgba(50, 150, 187, 0.2);
}

.container {
    width: var(--sidebar-width);
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--sidebar-background);
    border-right: var(--sidebar-border);
    transition: width 300ms cubic-bezier(0.4, 0, 0.4, 1);
    box-shadow: var(--sidebar-shadow);
}
.spacer {
    height: 100%;
    width: var(--sidebar-width);
    background: transparent;
    display: block;
    flex-shrink: 0;
    transition: width 300ms cubic-bezier(0.4, 0, 0.4, 1);
}

.spacer.collapsed,
.container.collapsed {
    width: calc(5 * var(--sidebar-factor));
}

.logo_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--sidebar-factor);
    height: calc(5 * var(--sidebar-factor));
    padding: var(--sidebar-factor);
    margin-bottom: calc(0.5 * var(--sidebar-factor));
    background: transparent;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: var(--sidebar-border);
}

.logo {
    width: calc(3 * var(--sidebar-factor));
    height: calc(3 * var(--sidebar-factor));
    padding: calc(0.5 * var(--sidebar-factor));
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--sidebar-border);
    border-radius: calc(0.75 * var(--sidebar-factor));
    flex-shrink: 0;
}

.logo svg {
    width: 100%;
    object-fit: contain;
    flex-shrink: 0;
}

.branding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: calc(0.25 * var(--sidebar-factor));
    flex-grow: 1;
}

.branding h1 {
    font-size: calc(1.1 * var(--sidebar-factor));
    font-weight: 600;
    color: var(--sidebar-text-color);
    margin: 0;
    line-height: 1em;
    user-select: none;
}
.branding p {
    margin: 0;
    color: var(--sidebar-label-color);
    font-size: calc(0.9 * var(--sidebar-factor));
    line-height: 1em;
    user-select: none;
}

.collapse_arrow {
    width: calc(0.5 * var(--sidebar-factor));
    object-fit: contain;
    fill: var(--sidebar-icon-color);
}

.content_container {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: calc(2 * var(--sidebar-factor));
}

/* Custom scrollbar */
.content_container {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--sidebar-scrollbar-color) transparent;
}
.content_container::-webkit-scrollbar {
    width: calc(0.3 * var(--sidebar-factor));
    background: transparent;
}
.content_container::-webkit-scrollbar-button {
    display: none;
}
.content_container::-webkit-scrollbar-track {
    background: transparent;
}
.content_container::-webkit-scrollbar-thumb {
    background-color: var(--sidebar-scrollbar-color);
    border: none;
    outline: none;
    border-radius: var(--sidebar-factor);
}
@-moz-document url-prefix() {
    .content_container::-webkit-scrollbar {
        display: none;
    }
}
/* ================ */

.menu {
    padding: calc(0.5 * var(--sidebar-factor));
    border-bottom: var(--sidebar-border);
}

.menu_label {
    height: calc(2 * var(--sidebar-factor));
    padding: 0 calc(0.5 * var(--sidebar-factor));
    font-weight: 600;
    font-size: calc(0.85 * var(--sidebar-factor));
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--sidebar-label-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sidebar-background);
}
.menu_collapse_arrow {
    height: calc(0.75 * var(--sidebar-factor));
    object-fit: contain;
    fill: var(--sidebar-icon-color);
    rotate: 180deg;
    transition: rotate 300ms cubic-bezier(0.4, 0, 0.4, 1);
}
.menu_collapse_arrow.active {
    rotate: 270deg;
}

.menu_content {
    display: flex;
    flex-direction: column;
    transition: all 300ms cubic-bezier(0.4, 0, 0.4, 1);
    transform-origin: top;
    overflow: hidden;
    max-height: 0;
}

.menu_content.active {
    max-height: calc(3 * var(--sidebar-menu-item-count) * var(--sidebar-factor));
}

.menu_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: calc(0.65 * var(--sidebar-factor));
    height: calc(3 * var(--sidebar-factor));
    padding: 0 calc(0.5 * var(--sidebar-factor));
    border-radius: calc(0.5 * var(--sidebar-factor));
    cursor: pointer;
    flex-shrink: 0;
    transition: all 150ms ease-in-out;
}

.container.collapsed .menu_item {
    justify-content: center;
}

.menu_item_icon {
    display: flex;
    align-items: center;
    user-select: none;
    width: calc(1.5 * var(--sidebar-factor));
    font-size: calc(1.5 * var(--sidebar-factor));
    object-fit: contain;
    color: var(--sidebar-icon-color);
    margin: 0;
}

.menu_item_label {
    font-size: var(--sidebar-factor);
    font-weight: 500;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: var(--sidebar-text-color);
}

.menu_item.active {
    background: var(--sidebar-hover-background);
}

.menu_item.active .menu_item_icon,
.menu_item:hover .menu_item_label,
.menu_item:hover .menu_item_icon,
.menu_item.active .menu_item_label {
    color: var(--sidebar-hover-text-color);
}
