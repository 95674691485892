.action_btn {
    position: relative;
    z-index: 10;
    outline: none;
    border: none;
    background: transparent;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
    color: #54656f;
    cursor: pointer;
    transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
}
.action_btn:disabled {
    color: #aaa;
    cursor: not-allowed;
}

.action_btn.active {
    transform: rotate(135deg);
    background: #d9dbdf;
}

.action_list {
    position: absolute;
    z-index: 10;
    bottom: 4rem;
    min-width: 10rem;
    left: 1rem;
    background: #fff;
    border-radius: 1rem;
    padding: 0.5rem;
    transform: translateY(0%) scale(0);
    pointer-events: none;
    transform-origin: bottom left;
    opacity: 0;
    box-shadow: 0px 1px 10px 0px rgba(87, 100, 110, 0.5);

    display: flex;
    flex-direction: column;

    transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
}

.action_list.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1);
}
