.message_input {
    outline: none;
    border: none;
    background: #fff;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    color: #010101;
    flex: 1;
    height: 100%;
    resize: none;
}

.message_input::placeholder {
    color: #888;
}

.send_btn {
    outline: none;
    border: none;
    background: transparent;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: #54656f;
    cursor: pointer;
    transition: all ease-in-out 150ms;
}

.send_btn:disabled {
    color: #aaa;
    cursor: not-allowed;
    transition: all ease-in-out 150ms;
}
