.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2221;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    transition: all ease-in-out 300ms;
}

.sidepanel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2222;
    width: 25rem;
    height: 100vh;

    transition: all ease-in-out 300ms;
    background-color: #fff;
    box-shadow: -2px 0px 26px rgba(0, 0, 0, 0.15);
}

.sidepanel_header {
    height: 3.5rem;
    overflow: hidden;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: #fff;
}

.sidepanel_header button {
    outline: none;
    border: none;
    color: #aaa;
    background: transparent;
    padding: 0;
    font-size: 1.2rem;
    height: fit-content;
}
.sidepanel_header p {
    color: #010101;
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
}

.tabs {
    display: grid;

    height: 2.5rem;
    overflow: hidden;

    position: relative;
    border-bottom: 1px solid #e3e3e3;
    z-index: 0;
}

.tab,
.active_tab {
    outline: none;
    background: transparent;
    border: none;
    padding: 0;
    font-weight: 600;
    color: #71777d;
    width: 100%;
    transition: all ease-in 100ms;
    position: relative;
    z-index: 2;
}
.active_tab {
    color: #39424c;
}
.active_tab_indicator {
    background-color: #f4f6f8;
    background: linear-gradient(to top, #f4f6f8, white);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all ease-in 100ms;
    z-index: 1;
}
.active_tab_indicator::before {
    content: "";
    background-color: #0098bd;
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: 0.25rem;
    transform: translateY(-50%);
}

.scroll_content {
    overflow-y: auto;
    height: calc(100vh - 2.5rem - 3.5rem);
    background-color: #fff;
}

.hidden {
    opacity: 0;
    transform: translateX(100%);
}
.show {
    opacity: 1;
    transform: translateX(0%);
}

.list_wrapper {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
