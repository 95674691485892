.table {
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.table::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.1rem solid #ddd;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.table td {
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
}

.table tr:nth-child(1) td {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
}
