.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0.25rem;

    position: relative;
}

.search {
    padding-left: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}

.search .search_btn {
    outline: none;
    border: none;
    background: transparent;
    margin-right: 0.4rem;
}

.search .search_btn svg {
    width: 1.5rem;
    height: 1.5rem;
}

.search .search_input {
    flex-grow: 1;
    border: none;
    outline: none;
    background: transparent;
    padding-right: 2.5rem;
    font-size: 0.8rem;
}

.clear_btn {
    border: 1px solid #000;
    border-radius: 50%;
    outline: none;
    background: transparent;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.clear_btn svg {
    width: 0.4rem;
    height: 0.4rem;
}

.search_filter {
    border-left: 1px solid #eee;
    padding: 0.35rem 0;
    padding-left: 0.3rem;
    padding-right: 0.2rem;
    border-radius: 10rem;
    background: #eee;
    position: relative;
}

.search_filter::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -0.4rem;
    width: 1px;
    height: 80%;
    transform: translateY(-50%);
    background: #eee;
}
.search_filter .dropdown {
    border: none;
    outline: none;
    background: transparent;
    padding-right: 0.1rem;
    font-size: 0.75rem;
    font-weight: 600;
}

.suggestion_container {
    width: 37.75rem;
    max-height: 25rem;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: calc(100% + 2px);
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    border-top: 0;
    right: 0;
    z-index: 2;
}
