.offcanvas-body {
  margin: 0;
  padding: 0 !important;
}

.call_record_box {
  display: block;
}

.call_record_box .counter_box {
  display: flex;
  background-color: #f4f4f4;
  width: 100%;
  height: 5.5rem;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 6px;
}

.call_record_box .counter_box .font_box {
  text-align: center;
}

.call_record_box .counter_box .font_box h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  color: #202020;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

.call_record_box .counter_box .font_box p {
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.call_record_box .tabbing_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 17px 2px;
}

.call_record_box .tabbing_box .nav-pills {
  width: 100%;
  box-shadow: inset 0 0 10px #cbcacabd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 5px;
  padding: 4px 4px;
  background-colr: white;
}

.call_record_box .tabbing_box .nav-pills .nav-item {
  width: 100%;
}

.call_record_box .tabbing_box .nav-pills .nav-item .nav-link {
  background-color: #ffffff03;
  color: #202020;
  border: none;
  width: 100%;
  font-size: 15px;
  padding: 4px 1px;
  text-transform: capitalize;
  border-radius: 4px;
}

.call_record_box .tabbing_box .nav-pills .nav-item .nav-link.active {
  color: var(--cl-white);
  border: 1px solid var(--cl-blue);
  background: var(--cl-blue);
  font-weight: bold;
}

.call_record_box .coll_coter_deta {
  width: 100%;
}

.call_record_box .coll_coter_deta h2 {
  font-size: 16px;
  font-weight: 700;
  color: #202020;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.call_record_box .coll_coter_deta h2::after {
  position: absolute;
  content: "";
  width: 62%;
  border-bottom: 1px dashed rgb(206 206 206 / 90%);
  top: 13px;
  right: 0;
}

.call_record_box .coll_coter_deta .date_font {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  margin: 5px 0 2px;
}

.call_record_box .coll_coter_deta .date_font p {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  color: #202020;
}

.call_record_box .coll_coter_deta .date_font p span {
  padding: 0 0 0 24px;
  margin: 0;
  font-size: 14px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #202020;
  position: relative;
}

.call_record_box .coll_coter_deta .date_font p span.bus {
  color: #ff8616;
}

.call_record_box .coll_coter_deta .date_font p span.no {
  color: #f83131;
}

.call_record_box .coll_coter_deta .date_font p span.ans {
  color: #609b47;
}

.call_record_box .coll_coter_deta .date_font p span.no-answer {
  color: #938d8d;
}
.call_record_box .coll_coter_deta .date_font p span.yellow {
  color: #f29567;
}

.call_record_box .coll_coter_deta .date_font .icon {
  height: auto;
  font-size: 18px;
  color: #202020;
}

.call_record_box .coll_coter_deta .date_font .no-answer p span::before {
  content: "";
  background-color: #c8c8c88f;
  height: 44px;
  width: 2px;
  position: absolute;
  top: -10px;
  left: 8px;
  border-radius: 100%;
}
