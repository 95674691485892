.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2221;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-color: transparent;
  transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
}

.sidepanel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2222;
  width: 30rem;
  height: 100vh;
  max-height: -webkit-fill-available;
  transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
  background-color: #fff;
  box-shadow: -2px 0px 26px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.sidepanel_header {
  height: 3.5rem;
  overflow: hidden;
  padding: 1rem;
  padding-left: 0;
  position: static;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #f6f6f6;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.back_btn {
  outline: none;
  border: none;
  background: transparent;
  color: #0079fb;
  font-size: 1.5rem;
  padding: 0.5rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_container {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

.profile_icon_name {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.profile_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
}

.profile_name {
  color: #131b20;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  max-width: 10rem;
  flex-shrink: 0;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_number {
  color: #697680;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
}

.refresh_btn {
  outline: none;
  border: none;
  background: transparent;
  font-size: 1.75rem;
  padding: 0.4rem;
  color: #0079fb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease-in-out 150ms;
}

.refresh_btn:hover {
  background: #eee;
}

.refresh_btn:disabled {
  color: #ccc;
  background: transparent;
  cursor: not-allowed;
}

.refresh_btn.loading {
  transform: rotate(0deg);
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tab_wrapper {
  width: 100%;
  background: #fff;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 3rem;
  position: relative;
  border-bottom: 1px solid #e3e3e3;
}

.tab,
.active_tab {
  outline: none;
  background: transparent;
  border: none;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #71777d;
  transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
  position: relative;
  z-index: 2;
}

.active_tab {
  color: #39424c;
}

.active_tab_indicator {
  background-color: #f4f6f8;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  transition: all cubic-bezier(0.4, 0, 0.4, 1) 300ms;
  z-index: 1;
}

.active_tab_indicator::before {
  content: "";
  background-color: #0098bd;
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.25rem;
  transform: translateY(-50%);
}

.scroll_content_wrapper {
  flex-grow: 1;
  position: relative;
  z-index: 1;
  background: #efeae2;
  overflow: auto;
}

.hidden {
  opacity: 0;
  transform: translateX(100%);
}

.show {
  opacity: 1;
  transform: translateX(0%);
}

/*  Whatsapp Chat UI */

.whatsapp_chat_wrapper {
  padding: 0 1rem 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  overscroll-behavior: contain;
  scroll-behavior: smooth;
}

.loader {
  position: fixed;
  inset: 0;
  /* background: rgba(240, 242, 245, 0.3); */
  background: #eeeae3;
  /* backdrop-filter: blur(10px); */

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: rgba(84, 101, 111, 0.7);
  transition: opacity cubic-bezier(0.4, 0, 0.4, 1) 300ms;
}

.loader svg {
  transform-origin: center;
  animation: spin 0.6s linear infinite;
}

.scroll_content_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("../../Static/RawImages/whatsapp_ui_bg.png");
  background-repeat: repeat;
  background-size: 412.5px 749.25px;
  background-position: center;
  opacity: 0.4;
}

.from_them,
.from_me {
  border-radius: 0.5rem;
  padding: 0.3rem;
  line-height: 1.25;
  max-width: 75%;
  position: relative;
  word-wrap: break-word;
  border-bottom: 1px solid #cfcbc5;

  margin: 0.5rem 0;
  width: fit-content;
  font-weight: 400;
  text-align: left;
}

.from_them {
  align-items: flex-start;
  background-color: #fefefe;
  color: #010101;
}

.from_me {
  align-self: flex-end;
  background-color: #e3ffd3;
  color: #010101;
}

.from_them::before,
.from_them::after,
.from_me::before,
.from_me::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

.from_me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #e3ffd3;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.from_me::after {
  background-color: #ebe7dc;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.from_them::before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #fefefe;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.from_them::after {
  background-color: #ebe7dc;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.from_me ~ .from_me {
  margin: 0.25rem 0 0;
}

.from_me ~ .from_me:not(:last-child) {
  margin: 0.25rem 0 0;
}

.from_me ~ .from_me:last-child {
  margin-bottom: 0.5rem;
}

.no_tail::before {
  display: none;
}

.margin_b_none {
  margin-bottom: 0 !important;
}

.chat_date {
  /* position: sticky; */
  top: 1rem;
  z-index: 999;
  align-self: center;

  width: 8rem;
  white-space: nowrap;
  background-color: #f4f0e8;
  color: #4a4950;
  border-radius: 200px;
  font-weight: 600;
  margin: 1.75rem 0 0.5rem 0;
  font-size: 0.8rem;
  text-align: center;
  padding: 0.25rem 0.7rem;
  border-bottom: 1px solid #cfcbc5;
}

.chat_time {
  color: #8a898e;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: right;
  margin: 0.4rem 0.8rem 0.6rem 2.5rem;
  line-height: 0.5rem;
}

.chat_content {
  padding: 0.2rem 0.575rem;
  padding-bottom: 0;
  white-space: pre-line;
}

.messaging_container,
.messaging_container_disabled {
  position: relative;
  z-index: 9;
  flex-shrink: 0;
  background: #f0f2f5;
  padding: 0.75rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.2rem;
  height: 7rem;
  transition: all ease-in-out 300ms;
}

.messaging_container_disabled {
  height: 5rem;
  align-items: center;
  justify-content: center;
}

.messaging_container_disabled p {
  white-space: normal;
  text-align: center;
}
