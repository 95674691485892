.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999998;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(6px);
}

.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    background: transparent;
    padding: 10% 1rem;
    pointer-events: none;

    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.container {
    pointer-events: auto;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    max-height: 450px;
    max-width: 768px;
    margin-inline: auto;
    border-radius: 10px;
    overflow: hidden;
}

.search_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid #f2f5f9;
    padding: 1rem;
    padding-bottom: 0.5rem;
}

.search_container > input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0;
    color: #111729;
    user-select: none;
}
.search_container > input::placeholder {
    color: #9da3ae;
}
.search_icon {
    fill: "#4A5567";
    font-size: 1.3rem;
}

.escape_btn {
    border: 2px solid #f0f0f1;
    background-color: #fff;
    border-radius: 0.5rem;
    color: #364153;
    font-size: 0.6rem;
    font-weight: 800;
    padding: 0.25rem 0.4rem;
}

.results_container {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.results {
    height: 100%;
    overflow: auto;
    padding: 1rem;
    padding-bottom: 10%;
}
.category_item {
    margin-bottom: 1.5rem;
}

.main_title {
    font-size: 1rem;
    font-weight: 700;
    color: #111729;
    margin: 0;
    margin-bottom: 0.6rem;
}
.item_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
}

.item {
    width: 100%;
    background: #f8fafc;
    border-radius: 10px;
    padding: 0.75rem;
    cursor: pointer;
    transition: all ease-in-out 150ms;
}
.item .page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
.item .page_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem 1.2rem;
    padding-left: 2.8rem;
    font-size: 0.75rem;
    flex-wrap: wrap;
    color: #4ba3e3;
}
.item .page_info > span {
    position: relative;
    white-space: nowrap;
}

.item .page_info > span::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: -0.1rem;
    width: 100%;
    height: 0.05rem;
    background-color: #4ba3e3;
}

.item:hover,
.item:focus {
    background: #4ba3e3;
}

.item:hover .page_info,
.item:focus .page_info {
    color: #fff;
}
.item:hover .page_info > span::before,
.item:focus .page_info > span::before {
    background-color: #fff;
}

.hash {
    border: 1px solid #f0f0f1;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5rem;
    color: #97a3b6;
    font-size: 0.9rem;
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 100ms;
}
.item:hover .hash,
.item:focus .hash {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.item_title {
    flex-grow: 1;
    margin: 0;
    color: #364153;
}

.item:hover .item_title,
.item:focus .item_title {
    color: #fff;
}

.arrow {
    color: #4a5567;
    font-size: 1rem;
}

.item:hover .arrow,
.item:focus .arrow {
    color: #fff;
}

.not_found {
    width: 100%;
    padding: 1rem;
    padding-top: 3rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: #111729;
}
.sad_emoji {
    font-size: 5rem;
    color: #e3e4e4;
}
