.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem;

    --text-color: #222;
    --background-color: white;
}
.container h1.glitch {
    color: var(--text-color);
    font-family: sans-serif;
    font-weight: 800;
    position: relative;
    font-size: 100px;
    padding: 30px;
}
.container h1.glitch::before,
.container h1.glitch::after {
    padding: 30px;
    color: var(--text-color);
    content: "Oops!";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    overflow: hidden;
    top: 0;
}
.container h1.glitch::before {
    left: 3px;
    text-shadow: -2px 0 red;
    animation-name: glitch-animation-1;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
}
.container h1.glitch::after {
    left: -3px;
    text-shadow: -2px 0 blue;
    animation-name: glitch-animation-2;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: reverse-alternate;
}

@keyframes glitch-animation-1 {
    0% {
        clip: rect(40px, 350px, 110px, 30px);
    }
    5% {
        clip: rect(102px, 350px, 7px, 30px);
    }
    10% {
        clip: rect(146px, 350px, 72px, 30px);
    }
    15% {
        clip: rect(141px, 350px, 125px, 30px);
    }
    20% {
        clip: rect(100px, 350px, 111px, 30px);
    }
    25% {
        clip: rect(49px, 350px, 38px, 30px);
    }
    30% {
        clip: rect(17px, 350px, 2px, 30px);
    }
    35% {
        clip: rect(82px, 350px, 77px, 30px);
    }
    40% {
        clip: rect(61px, 350px, 52px, 30px);
    }
    45% {
        clip: rect(61px, 350px, 97px, 30px);
    }
    50% {
        clip: rect(133px, 350px, 20px, 30px);
    }
    55% {
        clip: rect(49px, 350px, 105px, 30px);
    }
    60% {
        clip: rect(61px, 350px, 54px, 30px);
    }
    65% {
        clip: rect(113px, 350px, 12px, 30px);
    }
    70% {
        clip: rect(71px, 350px, 35px, 30px);
    }
    75% {
        clip: rect(150px, 350px, 88px, 30px);
    }
    80% {
        clip: rect(139px, 350px, 15px, 30px);
    }
    85% {
        clip: rect(61px, 350px, 77px, 30px);
    }
    90% {
        clip: rect(37px, 350px, 95px, 30px);
    }
    95% {
        clip: rect(7px, 350px, 19px, 30px);
    }
    100% {
        clip: rect(91px, 350px, 47px, 30px);
    }
}
@keyframes glitch-animation-2 {
    0% {
        clip: rect(86px, 350px, 43px, 30px);
    }
    5% {
        clip: rect(42px, 350px, 6px, 30px);
    }
    10% {
        clip: rect(17px, 350px, 61px, 30px);
    }
    15% {
        clip: rect(25px, 350px, 134px, 30px);
    }
    20% {
        clip: rect(33px, 350px, 4px, 30px);
    }
    25% {
        clip: rect(2px, 350px, 18px, 30px);
    }
    30% {
        clip: rect(134px, 350px, 86px, 30px);
    }
    35% {
        clip: rect(7px, 350px, 22px, 30px);
    }
    40% {
        clip: rect(3px, 350px, 82px, 30px);
    }
    45% {
        clip: rect(145px, 350px, 150px, 30px);
    }
    50% {
        clip: rect(81px, 350px, 39px, 30px);
    }
    55% {
        clip: rect(5px, 350px, 137px, 30px);
    }
    60% {
        clip: rect(126px, 350px, 9px, 30px);
    }
    65% {
        clip: rect(120px, 350px, 70px, 30px);
    }
    70% {
        clip: rect(46px, 350px, 79px, 30px);
    }
    75% {
        clip: rect(111px, 350px, 82px, 30px);
    }
    80% {
        clip: rect(123px, 350px, 18px, 30px);
    }
    85% {
        clip: rect(137px, 350px, 69px, 30px);
    }
    90% {
        clip: rect(145px, 350px, 68px, 30px);
    }
    95% {
        clip: rect(98px, 350px, 102px, 30px);
    }
    100% {
        clip: rect(84px, 350px, 124px, 30px);
    }
}
