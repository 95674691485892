.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2221;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    transition: all ease-in-out 300ms;
}

.sidepanel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2222;
    width: 32rem;
    height: 100vh;

    transition: all ease-in-out 300ms;
    background-color: #fff;
    box-shadow: -2px 0px 26px rgba(0, 0, 0, 0.15);
}

.sidepanel_header {
    height: 3rem;
    overflow: hidden;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: #f5f5f5;

    border-bottom: 0.05rem solid #a6a19e;
}

.sidepanel_header button {
    outline: none;
    border: none;
    color: #aaa;
    background: transparent;
    padding: 0;
    font-size: 1.2rem;
    height: fit-content;
}
.sidepanel_header p {
    color: #010101;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
}

.scroll_content {
    overflow-y: auto;
    height: calc(100vh - 3rem);
    background-color: #fff;
}

.hidden {
    opacity: 0;
    transform: translateX(100%);
}
.show {
    opacity: 1;
    transform: translateX(0%);
}

.email_list_wrapper {
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
}

.email_wrapper {
    border-bottom: 1px solid #eee;
    cursor: pointer;
    padding: 0.9rem 0.8rem;
    transition: all ease-in-out 200ms;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.3rem;
    background-color: transparent;
}

.email_wrapper:hover {
    background-color: rgb(242, 241, 241);
}

.email_avatar,
.reply_email_avatar {
    border-radius: 50%;
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: 0.5rem;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    flex-shrink: 0;
}
.email_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.email_from_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 0.4rem;
}

.email_from {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5f6369;
    font-size: 1rem;
    margin: 0;
    text-align: left;
}

.timestamp {
    flex-shrink: 0;
    white-space: nowrap;
    text-align: end;
    font-size: 0.9rem;
    margin: 0;
}

.subject {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 0.9rem;
}

/* EMAIL CONTENT */

.thread_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.back_button {
    background-color: transparent;
    border: none;
    color: #5f6369;
    padding: 0.1rem 0.3rem;
    height: 3.1rem;
    width: 40%;
    text-align: left;
    font-size: 2rem;
    margin: 0;
}

.reply_button {
    background-color: transparent;
    border: none;
    color: #5732f9;
    padding: 0.15rem 0.9rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-align: right;
    font-size: 0.9rem;
    margin: 0;
    margin-right: 0.8rem;
    border-radius: 0.3rem;

    transition: all ease-in-out 200ms;
}

.reply_button:hover {
    background-color: #eee;
}

.thread_scroll_content {
    overflow-y: auto;
    height: calc(100vh - 3.1rem);
    background-color: #fff;
}

.thread_subject {
    padding: 0 0.8rem;
    margin: 0;
    color: #010101;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
}

.thread_wrapper,
.reply_wrapper {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
    margin-bottom: 0.4rem;
    margin-top: 0.8rem;
}
.thread_wrapper {
    cursor: pointer;
}

.thread_wrapper div {
    width: 100%;
}
.mail_id_timestamp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

.thread_email_from {
    margin: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5f6369;
    font-size: 1rem;
    text-align: left;
}
.thread_timestamp {
    margin: 0;
    font-size: 0.9rem;
}
.email_body {
    width: 100%;
    height: 100%;
    transition: all ease-in-out 250ms;
}

.email_body iframe {
    width: 100%;
    height: 100%;
    overflow: scroll;
    pointer-events: auto;
}

.hide_email_body {
    overflow: hidden;
    height: 0;
    opacity: 0;
}

.reply_wrapper {
    border-top: 0.05rem solid #ddd;
}
.reply_email_avatar {
    background-color: #7e56c1;
    color: #fff;
}

.loading {
    text-align: center;
    font-style: italic;
    font-size: 0.9rem;
    padding: 2rem 0.8rem;
    width: 100%;
    margin: 0;
    animation: loading 1s infinite linear alternate;
}

@keyframes loading {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.reply_info {
    width: 100%;
    text-align: right;
    font-size: 0.85rem;
    color: #5732f9;
    font-style: italic;
    margin: 0;
    padding-right: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

.reply_info svg,
.reply_button svg {
    fill: #5732f9;
    width: 1rem;
    object-fit: contain;
}
