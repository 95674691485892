.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2221;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    transition: all ease-in-out 300ms;
}

.sidepanel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2222;
    width: 20rem;
    height: 100vh;

    transition: all ease-in-out 300ms;
    background-color: #fff;
    box-shadow: -2px 0px 26px rgba(0, 0, 0, 0.15);
}

.sidepanel_header {
    height: 3rem;
    overflow: hidden;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: #f5f5f5;

    border-bottom: 0.05rem solid #a6a19e;
}

.sidepanel_header button {
    outline: none;
    border: none;
    color: #aaa;
    background: transparent;
    padding: 0;
    font-size: 1.2rem;
    height: fit-content;
}
.sidepanel_header p {
    color: #010101;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
}

.scroll_content {
    overflow-y: auto;
    height: calc(100vh - 3rem);
    background-color: #fff;
}

.hidden {
    opacity: 0;
    transform: translateX(100%);
}
.show {
    opacity: 1;
    transform: translateX(0%);
}

.message_list_wrapper {
    padding: 0.8rem;
    padding-bottom: 1.5rem;
}

.record_orange {
    background-image: url("../../../../Static/RawImages/record_bar-orange.svg");
}
.record_yellow {
    background-image: url("../../../../Static/RawImages/record_bar-yellow.svg");
}
.record_purple {
    background-image: url("../../../../Static/RawImages/record_bar-purple.svg");
}
