.whatsapp_preview_custom_audio_player {
    width: 100%;
    min-width: 20rem;
    background: transparent;
    background: rgba(218, 212, 201, 0.3);
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-left: 0;
    margin-bottom: 0.25rem;
}

.whatsapp_preview_custom_audio_player .rhap_additional-controls {
    display: none !important;
}
.whatsapp_preview_custom_audio_player .rhap_volume-controls {
    display: none !important;
}
.whatsapp_preview_custom_audio_player .rhap_controls-section {
    flex: unset !important;
}
.whatsapp_preview_custom_audio_player .rhap_progress-section {
    font-family: "Open Sans", sans-serif;
}
.whatsapp_preview_custom_audio_player .rhap_progress-section .rhap_time {
    font-size: 14px !important;
}
.whatsapp_preview_custom_audio_player .rhap_progress-bar {
    height: 0.25rem;
    background: #cfd0d1;
}
.whatsapp_preview_custom_audio_player .rhap_progress-indicator {
    width: 0.7rem;
    height: 0.7rem;
    margin-left: -0.35rem;
    top: -0.25rem;
    box-shadow: none;
    background: #70c1f2;
}
.whatsapp_preview_custom_audio_player .rhap_progress-filled {
    background: #868a8d;
}
.whatsapp_preview_custom_audio_player .rhap_main-controls .rhap_play-pause-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
}
