.loader_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    background: rgba(255, 255, 255, 0.65);
    z-index: 999;
}

.logo {
    flex-shrink: 0;
    width: 8rem;
    object-fit: contain;
}

.inner_circle {
    transform-origin: center;
    animation: rotate ease-out infinite 1s;
    animation-delay: 0.1s;
}

.outer_circle {
    transform-origin: center;
    animation: rotate ease-out infinite 1s;
}

@keyframes rotate {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}
